<template>
	<div class="active-album-page">
		<div class="page-title mb20">活动相册</div>
		<div class="flex flex-end mb20">
			<a-button type="primary" @click="toAddImgs">
				添加相册
			</a-button>
		</div>
		<div class="list">
			<div class="item" v-for="(item,index) in list" :key="index">
				<div class="top  flex space-between align-center">
					<div class="name">
						<span class="fbr">{{item.do_admin_name}}</span>
						<span class="active-name">{{item.title}}</span>
					</div>
					<div class="right flex">
						<div class="status">{{item.is_fabu == 1 ? '已发布' : '未发布'}}</div>
						<div class="time">发布时间:{{item.create_at_str}}</div>
					</div>
				</div> 
				<div class="img-list">
					<div class="img" v-for="(img,ind) in item.content" :key="ind">
						<img :src="$imglink+img">
					</div>
					<!-- <div class="img">
						<img src="">
					</div>
					<div class="img">
						<img src="">
					</div>
					<div class="img">
						<img src="">
					</div>
					<div class="img">
						<img src="">
					</div>
					<div class="img">
						<img src="">
					</div>
					<div class="img">
						<img src="">
					</div>
					<div class="img">
						<img src="">
					</div> -->
				</div>
				<div class="bottom flex flex-end">
					<a-popconfirm title="确认删除该相册吗" ok-text="是" cancel-text="否" @confirm="deleteAlbum(item)">
						<a-button type="danger">
							删除
						</a-button>
					</a-popconfirm>
					<a-button type="primary" @click= toEdit(item)>编辑</a-button>
					<a-button type="primary" @click="sendPublic(item)">{{ item.is_fabu == 1 ? '撤回' : '确认'}}发布</a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataLoading: false,
				list: [],
				page: {
					current: 1,
					total: 0
				}
			}
		},
		computed: {},
		created() {

		},
		mounted() {
			this.getData()
		},
		filters: {
			zhsz(value) {
				return value.split(',')
			}
		},
		methods: {
			toAddImgs() {
				this.$router.push({path:'/study/addImgs'})
			},
			getData() {
				let t = this
				t.dataLoading = true
				t.$get({
					port: 'a',
					url: 'airticleList',
					data: {
						type: 5,
						page: t.page.current,
						searchInfo: t.searchInfo
					}
				}).then(res => {
					t.dataLoading = false
					let { code, data, msg } = res
					if (code == 0) {
						for(let i = 0; i < data.list.length;i++) {
							data.list[i].content = data.list[i].content.split(',')
						}
						t.list = data.list
						t.page.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.dataLoading = false
				})
			},
			deleteAlbum(item) {
				let t = this
				t.$get({
					port: 'a',
					url: 'airticleDel',
					data: {airticle_id: item.airticle_id}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success(msg, 1.5)
						t.getData()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			sendPublic(item) {
				let t = this
				t.$get({
					port: 'a',
					url: 'xiangceFabu',
					data: {airticle_id: item.airticle_id}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success(msg, 1.5)
						t.getData()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				})
			},
			toEdit(item) {
				this.$router.push({path:'/study/addImgs',query:{airticle_id:item.airticle_id}})
			}
		}
	}
</script>

<style lang="less">
	.active-album-page {
		.list {
			display: block;
			.item {
				border-radius: 10px;
				border: 1px solid #eee;
				margin-bottom: 20px;
				.top {
					border-bottom: 1px solid #eee;
					padding: 0 20px;
					margin-bottom: 10px;
					.right {
						.status {
							margin-right: 10px;
						}
					}
				}
				.name {
					line-height: 50px;
					.fbr {
						font-size: 16px;
						color: #333;
						margin-right: 10px;
					}
					.active-name {
						font-size: 14px;
					}
				}
				.top {
					
				}
				
				.img-list {
					padding: 1px 20px;
					display: flex;
					flex-wrap: wrap;
					.img {
						width: 200px;
						height: 120px;
						border-radius: 6px;
						background-color: rgba(0,0,0,.02);
						margin: 0 10px 10px 0;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;	
						}
					}
				}
				.bottom {
					padding: 10px 20px;
					border-top: 1px solid #eee;
					.ant-btn {
						margin-right: 10px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
	}
</style>
